// import React, { useEffect, useState } from 'react';

// const VerticalTabs = ({ tabs  , selectedIndexValue}) => {

//     const [activeTab, setActiveTab] = useState(0);

//     const handleTabClick = (index) => {
//         setActiveTab(index);
//     };

//     useEffect(() => {
//         if(selectedIndexValue){
//             setActiveTab(selectedIndexValue)
//         }
//     } , [selectedIndexValue])

//     return (
//         <div className="row vertical-tabs">
//             <div className="col-sm-4">
//                 <div className="tab-list sidejob_cards">
//                     {tabs.map((tab, index) => (
//                         <div
//                             key={index}
//                             className={`tab ${index === activeTab ? 'active' : ''}`}
//                             onClick={() => handleTabClick(index)}
//                         >
//                             {tab.title}
//                         </div>
//                     ))}
//                 </div>
//             </div>
//             <div className="col-sm-8">
//                 <div className="tab-content detailsbox">
//                     {tabs[activeTab].content}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default VerticalTabs;


import React, { useEffect, useState, useRef } from 'react';

const VerticalTabs = ({ tabs, selectedIndexValue }) => {
    const [activeTab, setActiveTab] = useState(0);
    const tabRefs = useRef([]);  

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {
        if (selectedIndexValue !== undefined && selectedIndexValue !== null) {
            setActiveTab(selectedIndexValue);
        }
    }, [selectedIndexValue]);

    useEffect(() => {
        if (tabRefs.current[activeTab]) {
            tabRefs.current[activeTab].scrollIntoView({
                behavior: 'smooth',
                block: 'start',      
            });
        }
    }, [activeTab]);

    return (
        <div className="row vertical-tabs">
            <div className="col-sm-4">
                <div className="tab-list sidejob_cards">
                    {tabs.map((tab, index) => (
                        <div
                            key={index}
                            ref={(el) => (tabRefs.current[index] = el)} 
                            className={`tab ${index === activeTab ? 'active' : ''}`}
                            onClick={() => handleTabClick(index)}
                        >
                            {tab.title}
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-sm-8">
                <div className="tab-content detailsbox">
                    {tabs[activeTab].content}
                </div>
            </div>
        </div>
    );
};

export default VerticalTabs;

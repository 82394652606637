import logo from '../images/logo.png';

let config = {}
// config['BASE_URL'] = 'http://localhost:3000';
// config['API_URL'] = 'http://localhost:3001/api/';
// config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117'; 

config['BASE_URL'] = 'https://api-hrms.dtsmis.in:3008/v1';
config['API_URL'] = 'http://localhost:8080/v1/admin/';
config['IMAGE_PATH'] = 'http://localhost:8080/public/uploads/';
config['FRONT_URL'] = 'https://front-hrms.dtsmis.in/';
config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117';

config['LOGO_PATH'] = logo;

config['COMPANY_NAME'] = 'HRMS WEB APP';  
config['PANEL_NAME'] = 'HR Login';
config['HELP_URL'] = 'support';
config['PRIVACY_URL'] = 'pivacy-policy';
config['TERMS_URL'] = 'terms-conditions';

export default config;


// import logo from '../images/logo.png';

// let config = {}

// config['BASE_URL'] = 'https://hrapi.hlfppt.org/v1';
// config['API_URL'] = 'https://hrapi.hlfppt.org/v1/admin/';
// config['IMAGE_PATH'] = 'https://hrapi.hlfppt.org/public/uploads/';
// config['FRONT_URL'] = 'https://jobs.hlfppt.org/';
// config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117';

// config['LOGO_PATH'] = logo;

// config['COMPANY_NAME'] = 'HRMS WEB APP';  
// config['PANEL_NAME'] = 'HRMS Login';
// config['HELP_URL'] = 'support';
// config['PRIVACY_URL'] = 'pivacy-policy';
// config['TERMS_URL'] = 'terms-conditions';

// export default config;




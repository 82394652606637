import { createSlice } from "@reduxjs/toolkit";

// create the stream and Location 
const initialState = {
     file:null,
}

const CollectFilesData = createSlice({
    name: 'files',
    initialState,
    reducers:{
        setFiles: (state, action) => {
            state.file = action.payload
        },
    }
})

export const { setFiles}  = CollectFilesData.actions;
export default CollectFilesData.reducer;


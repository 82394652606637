import React from "react";
import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import JobListing from "./job-portal/JobListing";
import JobDetails from "./job-portal/JobDetails";
import ApplyNow from "./job-portal/Applynow";
import JobNavbar from "./job-portal/JobNav";

/************** Candidate Profile To Add the Login Page *************/
import Login from "./candidate_portal/src/Login";
import Welcome from "./candidate_portal/src/components/Welcome";
import Knowhlfppt from "./candidate_portal/src/components/Know_hlfppt";
import Assessment from "./candidate_portal/src/components/Assessment";
import AssessmentScore from "./candidate_portal/src/components/Assessment_score";
import Profile from "./candidate_portal/src/components/Profile";
import UploadDocument from "./candidate_portal/src/components/Upload_documents";
import YourDocument from "./candidate_portal/src/components/Your_documents";
import MyComponent from "./candidate_portal/src/components/My_components";
import { ProtectedRoute } from "./candidate_portal/src/helper/Auth_Helper";
// import { Verifiedd } from "./components/Verify";
import Urlvarify from './candidate_portal/src/components/Urlverify';
// import MyComponent from "./components/My_components";



const Layout = ({ children }) => {
  const location = useLocation();
  const path = location.pathname;

  const showNavbar = 
    path === "/" ||
    path === "/job-listing" ||
    path.startsWith("/job-details/") || // Matches any dynamic route like /job-details/:id
    path.startsWith("/apply/");    

  return (
    <>
      {showNavbar ? <JobNavbar /> :
        path !== '/login' && path !== '/verify' &&  <MyComponent />
       }
      {children}
    </>
  );
};



function App() {
  return (
    <>
      <BrowserRouter>
        <Layout>
          <Routes>
            {/* Routes with Navbar */}
            <Route path="/" element={<JobListing />} />
            <Route path="/job-listing" element={<JobListing />} />
            <Route path="/job-details/:id" element={<JobDetails />} />
            <Route path="/apply/:id" element={<ApplyNow />} />

            {/* Routes without Navbar */}
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/verify' element={<Urlvarify />} />
            <Route exact path='/dashboard' element={<ProtectedRoute><Welcome /></ProtectedRoute>} />
            <Route exact path='/knowhlfppt' element={<ProtectedRoute><Knowhlfppt /></ProtectedRoute>} />
            <Route exact path='/assessment' element={<ProtectedRoute><Assessment /></ProtectedRoute>} />
            <Route exact path='/assessment-score' element={<ProtectedRoute><AssessmentScore /></ProtectedRoute>} />
            <Route exact path='/profile' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route exact path='/upload-documents' element={<ProtectedRoute><UploadDocument /></ProtectedRoute>} />
            <Route exact path='/your-document' element={<ProtectedRoute><YourDocument /></ProtectedRoute>} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import JobCards from "./JobCards";
import TopBanner from "./TopBanner";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { InfinitySpin } from 'react-loader-spinner';
import notFoundImage from '../images/notFound.png';
import connectionError from '../images/ConnectionError.jpg';
import { FetchJobsList } from "../Redux/Slices/JobListApi";

function Job_listing() {
    const [currentPage, setCurrentPage] = useState(9);
    const [loadingMore, setLoadingMore] = useState(false); // Track loading for more jobs
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(FetchJobsList({ stream: '', location: '', par_Page_record: `${currentPage}` }));
    }, [dispatch, currentPage]);

    const selectedStream = useSelector((state) => state.filter.selectedStream);
    const Jobs = useSelector(state => state.jobs.jobsList);

    const handleViewMore = () => {
        setLoadingMore(true); // Set loading more to true
        const nextPage = currentPage + 6; // Calculate the next page count
        setCurrentPage(nextPage); // Update current page state
        dispatch(FetchJobsList({ stream: '', location: '', par_Page_record: `${nextPage}` }))
            .finally(() => setLoadingMore(false)); // Reset loading more state after fetch
    };

    const hasMoreJobs = Jobs?.data?.data && Jobs.data.data.length >= currentPage;


    return (
        <>
            <TopBanner />

            <div className="container">
                <div className="joblist_display mt-4">
                    <div className="jobcounts">
                        <h5>
                            <span>{Jobs.data.length !== 0 ? Jobs.data.data.length : 0}</span> Jobs Openings In {selectedStream === '' ? 'All' : selectedStream} Stream
                        </h5>
                    </div>
                    <div className="row">
                        {Jobs.status === 'loading' && !loadingMore ? ( // Show spinner only during initial load
                            <div className="d-flex align-items-center justify-content-center" role="status">
                                <InfinitySpin
                                    visible={true}
                                    width="400"
                                    color="#30A9E2"
                                    ariaLabel="infinity-spin-loading"
                                />
                            </div>
                        ) : Jobs.status === 'failed' ? ( // Show error image
                            <div className="d-flex align-items-center justify-content-center">
                                <img className="" style={{ maxWidth: '200px', maxHeight: '200px' }} src={connectionError} alt="Data not found" />
                            </div>
                        ) : Jobs.data.length === 0 ? ( // Show not found image if data is empty after loading
                            <div className="d-flex align-items-center justify-content-center">
                                <img className="" style={{ maxWidth: '200px', maxHeight: '200px' }} src={notFoundImage} alt="Data not found" />
                            </div>
                        ) : (
                            Jobs.data.data.map((job) => (
                                <div className="col-sm-4" key={job._id}>
                                    <Link to={`/job-details/${job._id}`}>
                                        <JobCards jobs={job} />
                                    </Link>
                                </div>
                            ))
                        )}
                        {/* Adding pagination with dynamic page name */}
                        {(hasMoreJobs || loadingMore) && (
                            <div className="pagination">
                                <button
                                    className="page-button"
                                    onClick={handleViewMore}
                                    disabled={loadingMore} // Disable button while loading more
                                >
                                    {loadingMore ? (
                                        <div className="d-flex align-items-center">
                                            <span>Loading...</span>
                                        </div>
                                    ) : (
                                        "View More"
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Job_listing;
